.c-accordion {
  border-bottom: 1px solid $color-grey-600;

  &__header {
    @include flexbox;
    @include align-items(flex-start);
    @include justify-content(space-between);
    padding: $spacing-s;
    cursor: pointer;
    border: 0;
    background-color: $color-white;

    &:hover, &.is-selected {
      background-color: $color-blue-100;
    }

    .-arrow-first & {
      @include flex-direction(row-reverse);
    }

  }

  &__header-subtitle {
    width: 100%;
    margin: 0;
    padding-bottom: 1rem;
    padding-left: 1rem;
    color: $color-grey-700;
  }

  &__button {
    @include flex-grow(1);
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    color: $color-blue-500;
    border: 0;
    background-color: transparent;

    &.-h4 {
      font-size: $font-size-h4;
      margin: 0;
    }

    &.-h5 {
      font-size: $font-size-h5;
      margin: 0;
    }

    &.-h3 {
      font-size: $font-size-h3;
      margin: 0;
      color: $color-blue-500;
    }
  }

  &__toggle-btn {
    margin-left: $spacing-s;

    flex-shrink: 0;

    .-arrow-first & {
      margin-right: $spacing-xs;
      margin-left: 0;
    }
  }

  &__content {
    padding: $spacing-s;
    padding-bottom: $spacing-m;

    &.-form {
      padding: 0;
    }

    .-arrow-first & {
      padding-left: 2.65rem;
    }
  }

  &__form-container {
    padding: $spacing-m $spacing-s !important; 
  }

  &__expand-all {
    padding: $spacing-s;
    text-align: right;
    border-bottom: 1px solid $color-grey-600;
  }
}

@include tablet() {
  .c-accordion {
    &__toggle-btn {
      display: inline-block;
      overflow: hidden;
      width: 1em;
      white-space: nowrap;
    }
  }
}