.c-inschrijven-nieuwsbrief {
  &__container {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items (center);
    padding: $spacing-l;
    border: 2px solid $color-blue-300;
    border-radius: .5rem; 
    }

  &__image-box {
    max-width: 34%;
    margin-right: 2rem;

    object-fit: cover;
  }

  &__image {
    max-width: 100%;
  }

  &__title {
    margin-top: 0;
  }

  &__socials {
    padding-top: $spacing-xl;
    padding-left: $spacing-m;
  }
}

@include screen {
  .c-inschrijven-nieuwsbrief {

    &__container {
      @include flex-direction(column);
      padding: $spacing-m;

    }

    &__button {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    &__email {
      width: 100%;
    }

    &__image-box {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: $spacing-l;
    }

    &__image {
      object-fit: cover;
    }

    &__socials {
      padding: 0;
      text-align: center;
    }

  }
}

.c-inschrijven-nieuwsbrief-sidebar {

  &__container {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items (center);
    @include flex-direction(column); 
    padding: $spacing-l;
    border: 2px solid $color-blue-300;
    border-radius: .5rem; 
    }

  &__content {
    width: 100%;
  }

  &__email {
    width: 100%;
  }

  &__title {
    margin-top: 0;
  }

  &__image-box {
    margin-bottom: $spacing-l;
  }

  &__image {
    max-width: 100%;

    object-fit: cover;
  }

  &__social {
    margin-top: $spacing-m;
    text-align: center;
  }
}