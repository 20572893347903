$font-path: '../../fonts/';


@mixin font-face($font-family, $font-weight, $font-style, $src-filename) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;

    //src: url('#{$font-path}#{$src-filename}.eot');
    src: url('#{$font-path}#{$src-filename}.eot?#iefix') format('embedded-opentype'),
    //sass-lint:disable-line no-duplicate-properties
    url('#{$font-path}#{$src-filename}.woff') format('woff'),
    url('#{$font-path}#{$src-filename}.ttf') format('truetype'),
    url('#{$font-path}#{$src-filename}.svg##{$src-filename}') format('svg');
  }
}

@include font-face(Flanders Art Sans, 200, normal, FlandersArtSans-Light);
@include font-face(Flanders Art Sans, 400, normal, FlandersArtSans-Regular);
@include font-face(Flanders Art Sans, 500, normal, FlandersArtSans-Medium);
@include font-face(Flanders Art Sans, 700, normal, FlandersArtSans-Bold);
@include font-face(Flanders Art Sans, 200, italic, FlandersArtSans-LightItalic);