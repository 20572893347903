.c-socials-block {
  &__links {
    @include flexbox;
    color: $color-grey-900;
  }

  &__link {
    height: 40px;
    margin: 0;
    margin-right: $spacing-xs;
  }
}