.c-account-bullet {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  font-size: 1.5rem;
  font-weight: 500;
  overflow: hidden;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color-white;
  border: 2px solid $color-turquoise-500;
  border-radius: 50%;
  background-color: $color-turquoise-500;

  &__avatar {
    width: 100%;
  }
}