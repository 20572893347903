.inline-notification {
  @include flexbox;
  color: $color-grey-800;

  &.error {
    color: $color-red-700;
  }

  &.success {
    color: $color-green-700;
  }
}