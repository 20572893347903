.c-jobsuggesties {

  &__container {
    position: relative;
  }

  &__empty-card {
    padding: $spacing-m;
    border: 2px solid $color-blue-300;
    border-radius: $spacing-xs;
  }

  &__empty-box {
    width: 100%;
    background-color: $color-blue-300;

    &.-small {
      height: 2rem;
      margin-bottom: $spacing-s;
    }

    &.-large {
      height: 16rem;
    }
  }

  &__speech-bubble {
    position: relative;
    max-width: 50%;
    margin-left: $spacing-m;
    padding: $spacing-m;
    border-radius: .4em;
    background: $color-white;

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 0;
      height: 0;
      margin-top: -28.5px;
      margin-left: -2.5rem;
      content: '';
      border: 2.5rem solid transparent;
      border-top: 0;
      border-right-color: $color-white;
      border-left: 0;
    }
  }

  &__robot-talking {
    @include flexbox();
    @include align-items(center);
    position: absolute;
    bottom: 0;
  }
}

.c-jobsuggesties-error {
  &__container {
    @include flexbox();
    @include align-items(center);
    margin-top: $spacing-l;
  }

  &__content {
    margin-left: $spacing-l;
  }
}

@include screen() {
  .c-jobsuggesties {


    &__empty-card:nth-child(-n + 2) {
      display: none;
    }
    &__speech-bubble {
      max-width: 70%;
    }
  }
}

@include tablet {
  .c-jobsuggesties {

    &__robot-talking {
      margin-bottom: -2.5rem;
    }
  
  }

  .c-jobsuggesties-error {
    &__img {
      max-width: 30%;
    }
  }
}

@media (max-width: 498px) {
  .c-jobsuggesties {
    &__robot-talking {
      @include flex-wrap(wrap-reverse);
      bottom: 2.5rem;

    }
  }
}

@include phone() {

  .c-jobsuggesties {

    &__speech-bubble {
      position: relative;
      max-width: 100%;
      margin-left: 0;
      padding: $spacing-m;
      border-radius: .4em;
      background: $color-white;
  
      &:after {
        position: absolute;
        left: 50%;
        content: '';
        transform: translateY(345%);
        border: 2.5rem solid transparent;
        border-top-color: $color-white;
        border-bottom: 0;
        border-left: 0;

      }
    }

  
    &__robot-talking {
      @include flex-wrap(wrap-reverse);

      /*
        DELETE AFTER TEAM HTML CHANGES!

      margin-top: 0;
      transform: translateY(-103%); */
    }
  }

  .c-jobsuggesties-error {

    &__content {
      margin-left: 0;
    }
    &__container {
      @include flex-direction(column-reverse);
    }

    &__img {
      max-width: 100%;
    }
  }


}

@media (max-width: 345px) {
  .c-jobsuggesties {
    &__speech-bubble:after {
      display: none;
    }
  }
}