.field-password {
  input[type='password'] {
    @extend .text-inputfield-styling;
    @include phone {
      width: 100%;
    }
  }

  .icon-eye {
    &:before {
      font-size: 24px;
    }

    &:hover {
      &:before {
        color: $color-blue-500;
      }
    }
  }

  // Hide eye icon in input type=password on Edge
  input::-ms-reveal {
    display: none;
  }

  .wachtwoord-regels {
    @include flex-direction(column);

    .wachtwoord-regel {
      margin-top: $spacing-xs;
      margin-bottom: 0;
      color: $color-grey-800;
      &:before {
        margin-right: $spacing-xxs;
      }
      &.-success {
        color: $color-green-700;
      }
      &.-error {
        color: $color-red-700;
      }
    }
  }
}