.c-tip-block {
  margin-bottom: 1rem;
  padding: 0;
  border: 2px solid $color-blue-500;
  border-radius: $spacing-xs;
  background-color: $color-white;

  &__container {
    @include flexbox();
    @include screen() {
      flex-direction: column;
    }
    position: relative;
    word-break: break-word;
  }

  &__image-box {
    @include flexbox();
    @include justify-content(center);
    @include screen() {
      display: none;
    }
    width: 30%;
  }

  &__image {
    max-height: 18rem;
  }

  &__actions {
    position: absolute;
    top: 15px;
    right: 24px;
  }

  &__tag {
    @include screen() {
      margin-top: $spacing-s;
    }
    font-size: $font-size-h3;
    font-weight: 500;
    color: $color-yellow-700;
  }

  &__content {
    @include screen() {
      max-width: 100%;
      padding: 0 $spacing-s $spacing-s $spacing-s;
    }
    max-width: 60%;
    padding: $spacing-xl 0;

    align-self: center;
  }

  // hack for o-btn-group...
  .o-btn-group .-muted-link.u-show\@phone {
    @include minPhone {
      display: none !important;
    }
  }

}