/* custom huisstijl css */
.tooltip-icon-help, .tooltip-icon-info {
  @extend .icon;
  font-weight: 400;
  vertical-align: baseline;
  color: $color-lightgreen;
}

.tooltip-icon-info {
  @extend .icon-info;
}
.tooltip-icon-help {
  @extend .icon-help;
}

/* library css */
.tlite {
  font-size: .95rem;
  font-weight: 400;
  line-height: 1.2;
  position: absolute;
  z-index: 1000;
  display: block;
  visibility: hidden;
  width: 400px; //this functions as a max-width for the internal "actual" tooltip
  // The opacity is to fix animation for safari 9.0 and under
  -webkit-transition: transition .25s ease-out;
  transition: opacity .25s ease-out;
  text-align: center;
  opacity: 0;
}

.tlite-visible {
  visibility: visible;
  opacity: 1;
}

/*
tables need extra styling for the positioning of the tooltip
*/
.tlite-table tr td, .tlite-table tr th {
  position: relative;
}

.tlite .internal {
  display: inline-block;
  padding: $spacing-s $spacing-m;
  text-align: left;
  white-space: normal;
  text-decoration: none;
  text-transform: initial;
  pointer-events: none;
  color: $color-white;
  border-radius: 4px;
  background: $color-grey-800;
  box-shadow: 2px 4px 8px $color-box-shadow;
  
  &::before {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    content: ' ';
    transform: rotate(45deg);
    background: inherit;
  }
}

.tlite-n .internal::before {
  top: -3px;
  left: 50%;
  margin-left: -5px;
}

.tlite-nw .internal::before {
  top: -3px;
  left: 10px;
}

.tlite-ne .internal::before {
  top: -3px;
  right: 10px;
}

.tlite-s .internal::before {
  bottom: -3px;
  left: 50%;
  margin-left: -5px;
}

.tlite-se .internal::before {
  right: 10px;
  bottom: -3px;
}

.tlite-sw .internal::before {
  bottom: -3px;
  left: 10px;
}

.tlite-w {
  text-align: left;
  .internal::before {
    top: 50%;
    left: -3px;
    margin-top: -5px;
  }
}

.tlite-e {
  text-align: right;
  .internal::before {
    top: 50%;
    right: -3px;
    margin-top: -5px;
    
  } 
}