.c-content-block {

  &__image-box {
    float: right;
    width: 50%;
    margin-top: 50%;
    margin-right: -20rem;
    margin-left: 4rem;
  }

  &__caption {
    text-align: right;
  }

}