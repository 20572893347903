.c-in-de-kijker-block {
  padding: $spacing-l;
  border: 2px solid $color-orange-500;
  border-radius: .5rem;
  background-color: $color-grey-100;

  &__blok-title {
    font-size: $font-size-h3;
    margin: 0;
    margin-bottom: $spacing-m;
    color: $color-orange-500;

    &:before {
      @extend .icon-megaphone;
      @extend .icon;
      font-size: $font-size-h2;
      margin-right: $spacing-xs;
    }

    &.-in-main {
      margin-bottom: $spacing-l;
    }
  }

  &__content-container {
    @include flexbox();
    @include justify-content(space-between);
    overflow: hidden;
    width: 100%;
  }

  &__title {
    margin-top: 0;
  }

  &__text {
    margin-bottom: 2rem;
  }

  &__btn {
    color: $color-indigo-900;
    border: 1px solid $color-orange-500;
    background-color: $color-white;

    &:visited {
      color: $color-indigo-900;
    }

    &:hover {
      border: 1px solid $color-orange-500;
      background-color: $color-orange-100;
    }
  }

  &__image-box {
    position: relative;
    overflow: hidden;
    margin-top: -$spacing-l;
    margin-right: $spacing-m;
    margin-bottom: -$spacing-l;

    &.-in-sidebar {
      top: -$spacing-l;
      bottom: -$spacing-l;
      height: calc(100% + 4rem);
      margin: 0;
      margin-left: $spacing-l;
    }
  }

  &__image {
    position: absolute;
    max-width: 100%;
  }
}

@include screen() {
  .c-in-de-kijker-block {
    padding: $spacing-s;

    &__text {
      margin-bottom: $spacing-s;
    }

    &__btn {
      width: calc(100% - 2rem); // -2rem cause 100% makes them overflow
    }

    &__image-box {
      display: none;

      &.-in-sidebar {
        top: unset;
        bottom: -1rem;
        display: block;
        height: 100%;

      }
    }
  }
}
