.o-carousel {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  position: relative;
  margin-right: -3rem;
  margin-left: -3rem;

  /* Exeptional CSS for widget */
  .tns-nav-active>a {
    background-color: $color-grey-700;
  }

  .tns-nav-active {
    background-color: $color-grey-700;

    &:hover {
      background-color: $color-grey-300;
    }
  }

  .tns-nav {
    margin: .5rem 0;
    text-align: center;
  }

  .tns-nav button {
    display: inline-block;
    overflow: hidden;
    width: 10px;
    height: 10px;
    margin-right: .5rem;
    padding: 0;
    white-space: nowrap;
    text-decoration: none;
    border: 1px solid $color-grey-700;
    border-radius: 100%;
    background-color: $color-white;
  }

  .tns-nav button.tns-nav-active {
    background-color: $color-grey-700;
  }

  .tns-nav button:hover {
    background-color: $color-grey-300;
  }

  /* END - Exeptional CSS for widget  */

  &__dot-container {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
  }

  &__dot-list {
    @include flexbox();
  }

  &__dot {
    display: inline-block;
    overflow: hidden;
    max-width: .5rem;
    max-height: .5rem;
    margin-right: .5rem;
    white-space: nowrap;
    text-decoration: none;
    color: transparent;
    border: 1px solid $color-grey-700;
    border-radius: 100%;
    background-color: $color-white;

    &.-active {
      color: transparent;
      background-color: $color-grey-700;
    }

    &:hover {
      color: transparent;
      background-color: $color-grey-300;
    }

    &:visited {
      text-decoration: none;
      color: transparent;
    }

  }

  &__container {
    width: 100%;
    max-width: $wrapper-max-width;
  }

  &__nav-button {
    &[disabled] {
      visibility: hidden;
    }

    &:before {
      font-size: 3rem;
      margin-top: -.3125rem;
      margin-left: -.7rem;

    }

    &.-mobile {
      @include screen {
        display: inline;
        margin: -.6rem $spacing-s;
      }
      display: none;
    }
  }

  &__show-all {
    display: block;
    text-decoration: none;
  }
}

.o-carousel.has-dropdown\@screen {
  @include screen {
    .o-carousel__dot-container {
      @include justify-content(flex-start);
    }

    .o-carousel__dot-list {
      display: none;
    }

    .js-carousel__dropdown-toggle {
      display: block;
    }
  }

  .js-carousel__dropdown-toggle {
    display: none;
  }
}

@include screen() {
  .o-carousel {
    max-width: 100%;
    margin: 0;
    /* Exeptional CSS for widget */
    margin-bottom: 2rem;
    /* END Exeptional css for widget */

    &__show-all {
      margin-top: $spacing-m;
    }

    // for carousel demo purposes only!! Drupal js implementation hides the items automatically
    &__hide-for-demo:last-child {
      display: none;
    }

  }

  // END of - for carousel demo purposes only!!
}

@include tablet {
  .o-carousel {
    &__hide-for-demo:nth-child(2) {
      display: none;
    }
  }
}

@include screenMedium() {
  .o-carousel {
    margin: 0;
  }
}

@include screenLarge() {
  .o-carousel {
    margin: 0;
    &__nav-button {
      /*Exeptional css for widget*/
      position: absolute;
      bottom: 0;
      display: none;
      display: inline-block;

      &.action-back {
        left: 0;
      }

      &.action-go {
        right: 0;
      }

      /* END Exeptional css for widget */
    }
  }
}


/* 
  Page                          Used?      Type         Fixed desktop      fixed mobile
  Annonieme Jobs                Yes        Nav block    - (arrows to low)  YES
  Annonieme Oriënteren          Yes        Cards        -                  YES
  Ingelogde homepage - loading  Yes        tip                             YES
  Ingelogde homepage            Yes        tip          -                  YES
  Ingelogde Jobs                Yes        tip                             YES
  Ingelogde Jobs                Yes        Cards        -                  YES 
  Ingelogde Opleidingen         Yes        tip                             YES
  Ingelgode Oriënteren          Yes        tip
  Ingelogde Oriënteren          Yes        Cards
*/