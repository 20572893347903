.c-tag {
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  padding: 0 $spacing-s;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-white;
  border-radius: 18px;

  &__icon-close {
    font-size: .6rem;
    margin-left: .5rem;
    vertical-align: middle;
  
    &:before {
      line-height: 1.2rem;
      width: 1.2rem;
      margin-bottom: .19rem;
      text-align: center;
      border-radius: 50%;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.c-tag.-neutral { // waarom niet "&.-neutral" binnen de class ".c-tag"?
  background-color: $color-grey-600;
}
.c-tag.-neutral.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-neutral:before {
    background-color: $color-grey-800;
  }
}
.c-tag.-neutral.-light { // 1 modifier-class van maken om nesting te minimaliseren? weet het niet
  color: $color-grey-700;
  background-color: $color-grey-100;
}
.c-tag.-neutral.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-grey-300;

    .c-tag__icon-close.-neutral:before { // dit lijkt me niet te kloppen: een element class binnen een modifier class? Miss beter ".-neutral &" binnen de class "c-tag__icon-close" ?
      background-color: $color-grey-300;
    }
  }
}
.c-tag.-neutral.-invers {
  padding: 0;
  color: $color-grey-700;
  background-color: transparent;
}


.c-tag.-danger {
  background-color: $color-red-700;
}
.c-tag.-danger.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-danger:before {
    background-color: $color-red-900;
  }
}
.c-tag.-danger.-light {
  color: $color-red-700;
  background-color: $color-red-200;
}
.c-tag.-danger.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-red-300;

    .c-tag__icon-close.-danger:before {
      background-color: $color-red-300;
    }
  }
}


.c-tag.-success {
  background-color: $color-green-700;
}
.c-tag.-success.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-success:before {
    background-color: $color-green-900;
  }
}
.c-tag.-success.-light {
  color: $color-green-700;
  background-color: $color-green-200;
}
.c-tag.-success.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-green-300;

    .c-tag__icon-close.-success:before {
      background-color: $color-green-300;
    }
  }
}


.c-tag.-info {
  background-color: $color-blue-700;
}
.c-tag.-info.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-info:before {
    background-color: $color-blue-900;
  }
}
.c-tag.-info.-light {
  color: $color-blue-700;
  background-color: $color-blue-200;
}
.c-tag.-info.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-blue-300;

    .c-tag__icon-close.-info:before {
      background-color: $color-blue-300;
    }
  }
}


.c-tag.-warning {
  background-color: $color-orange-700;
}
.c-tag.-warning.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-warning:before {
    background-color: $color-orange-900;
  }
}
.c-tag.-warning.-light {
  color: $color-orange-700;
  background-color: $color-orange-200;
}
.c-tag.-warning.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-orange-300;

    .c-tag__icon-close.-warning:before {
      background-color: $color-orange-300;
    }
  }
}


.c-tag.-yellow {
  background-color: $color-yellow-700;
}
.c-tag.-yellow.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-yellow:before {
    background-color: $color-yellow-900;
  }
}
.c-tag.-yellow.-light {
  color: $color-yellow-700;
  background-color: $color-yellow-100;
}
.c-tag.-yellow.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-yellow-300;

    .c-tag__icon-close.-yellow:before {
      background-color: $color-yellow-300;
    }
  }
}


.c-tag.-lightgreen {
  background-color: $color-lightgreen-500;
}
.c-tag.-lightgreen.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-lightgreen:before {
    background-color: $color-lightgreen-600;
  }
}
.c-tag.-lightgreen.-light {
  color: $color-lightgreen-500;
  background-color: $color-lightgreen-200; 
}
.c-tag.-lightgreen.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-lightgreen-400;

    .c-tag__icon-close.-lightgreen:before {
      background-color: $color-lightgreen-400;
    }
  }
}


.c-tag.-turquoise {
  background-color: $color-turquoise-700;
}
.c-tag.-turquoise.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-turquoise:before {
    background-color: $color-turquoise-900;
  }
}
.c-tag.-turquoise.-light {
  color: $color-turquoise-700;
  background-color: $color-turquoise-200; 
}
.c-tag.-turquoise.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-turquoise-300;

    .c-tag__icon-close.-turquoise:before {
      background-color: $color-turquoise-300;
    }
  }
}


.c-tag.-blue {
  background-color: $color-blue-500;
}
.c-tag.-blue.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-blue:before {
    background-color: $color-blue-900;
  }
}
.c-tag.-blue.-light {
  color: $color-blue-500;
  background-color: $color-blue-100; 
}
.c-tag.-blue.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-blue-300;

    .c-tag__icon-close.-blue:before {
      background-color: $color-blue-300;
    }
  }
}


.c-tag.-purple {
  background-color: $color-purple-a500;
}
.c-tag.-purple.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-purple:before {
    background-color: $color-purple-900;
  }
}
.c-tag.-purple.-light {
  color: $color-purple-a500;
  background-color: $color-purple-100; 
}
.c-tag.-purple.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-purple-300;

    .c-tag__icon-close.-purple:before {
      background-color: $color-purple-300;
    }
  }
}


.c-tag.-burgundy {
  background-color: $color-burgundy-500;
}
.c-tag.-burgundy.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-burgundy:before {
    background-color: $color-burgundy-900;
  }
}
.c-tag.-burgundy.-light {
  color: $color-burgundy-500;
  background-color: $color-burgundy-100; 
}
.c-tag.-burgundy.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-burgundy-300;

    .c-tag__icon-close.-burgundy:before {
      background-color: $color-burgundy-300;
    }
  }
}

.c-tag.-grey {
  background-color: $color-grey-900;
}
.c-tag.-grey.-removable:not(.-light):hover {
  color: $color-white;
  .c-tag__icon-close.-grey:before {
    background-color: $color-black;
  }
}
.c-tag.-grey.-light {
  color: $color-grey-900;
  background-color: $color-grey-100; 
}
.c-tag.-grey.-light.-removable {
  &:hover {
    box-shadow: inset 0 0 0 1px $color-grey-300;

    .c-tag__icon-close.-grey:before {
      background-color: $color-grey-300;
    }
  }
}

.c-tag.-black {
  background-color: $color-black;
}

  

/*
// TO DELETE AFTER DRUPAL CHANGES
.tag {
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
  padding: 0 $spacing-s;
  text-transform: uppercase;
  color: color-white;
  border-radius: 18px;

  &.removable {
    text-decoration: none;
    padding: 0 $spacing-xs;

    .icon-close {
      font-size: 0.6rem;

      &:before {
        margin-bottom: 0.19rem;
        width: 1.2rem;
      }
    }

    &:hover {
      .icon-close:before {
        line-height: 1.2rem;
        width: 1.2rem;
        text-align: center;
        border-radius: 50%;
      }
    }
    
  }

  &.neutral {
  background-color: $color-grey-600;

    &.light {
      color: $color-grey-700;
      background-color: $color-grey-100;

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-grey-300;

        .icon-close:before {
          background-color: $color-grey-300;
        }
      }
    }

    &.invers {
      padding: 0;
      color: $color-grey-700;
      background-color: transparent;
    }
  }

  &.danger {
  background-color: $color-red-700;

    &.light {
      color: $color-red-700;
      background-color: $color-red-200;

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-red-300;

        .icon-close:before {
          background-color: $color-red-300;
        }
      }
    }

  }

  &.info {
  background-color: $color-blue-700;

    &.light {
      color: $color-blue-700;
      background-color: $color-blue-200;

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-blue-300;

        .icon-close:before {
          background-color: $color-blue-300;
        }
      }
    }
  }

  &.success {
  background-color: $color-green-700;

    &.light {
      color: $color-green-700;
      background-color: $color-green-200;

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-green-300;

        .icon-close:before {
          background-color: $color-green-300;
        }
      }
    }
  }

  &.warning {
    background-color: $color-orange-700;

    &.light {
      color: $color-orange-700;
      background-color: $color-orange-200;

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-orange-300;

        .icon-close:before {
          background-color: $color-orange-300;
        }
      }
    }

  }

  &.yellow {
    background-color: $color-yellow-700;

    &.light {
      color: $color-yellow-700;
      background-color: $color-yellow-100;
      
      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-yellow-300;
        
        .icon-close:before {
          background-color: $color-yellow-300;
        }
      }
    }
  }

  &.lightgreen {
    background-color: $color-lightgreen-500;

    &.light {
      color: $color-lightgreen-500;
      background-color: $color-lightgreen-200; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-lightgreen-400;
        
        .icon-close:before {
          background-color: $color-lightgreen-400;
        }
      }
    }
  }

  &.turquoise {
    background-color: $color-turquoise-700;

    &.light {
      color: $color-turquoise-700;
      background-color: $color-turquoise-200; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-turquoise-300;
        
        .icon-close:before {
          background-color: $color-turquoise-300;
        }
      }
      
    }
  }

  &.blue {
    background-color: $color-blue-500;

    &.light {
      color: $color-blue-500;
      background-color: $color-blue-100; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-blue-300;
        
        .icon-close:before {
          background-color: $color-blue-300;
        }
      }
    }
  }

  &.purple {
    background-color: $color-purple-a500;

    &.light {
      color: $color-purple-a500;
      background-color: $color-purple-100; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-purple-300;
        
        .icon-close:before {
          background-color: $color-purple-300;
        }
      }

    }
  }

  &.burgundy {
    background-color: $color-burgundy-500;

    &.light {
      color: $color-burgundy-500;
      background-color: $color-burgundy-100; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-burgundy-300;
        
        .icon-close:before {
          background-color: $color-burgundy-300;
        }
      }
    }
  }

  &.grey {
    background-color: $color-grey-900;

    &.light {
      color: $color-grey-900;
      background-color: $color-grey-100; 

      &.removable:hover {
        box-shadow: inset 0px 0px 0px 1px $color-grey-300;
        
        .icon-close:before {
          background-color: $color-grey-300;
        }
      }
    }
  }

  &.black {
    background-color: $color-black;
  }
}
// END of TO DELETE AFTER DRUPAL CHANGES
*/