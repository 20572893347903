.c-wizard {

  //styling layout 

  .c-wizard__header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    .c-wizard__header-info {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
    }

    .c-wizard__header-info-title {
      // edit JS for inschrijvingswizard v4
      margin-bottom: 0;
    }

    .c-wizard__header-info-duurtijd {
      display: inline;
      margin-left: $spacing-s;
    }

    .c-wizard__header-close {
      text-align: right;
    }
  }

  .c-wizard__container {
    padding: 0;
    border: 2px solid $color-blue-200;
    border-radius: 8px;

    .c-wizard__container-form {
      padding: $spacing-l $spacing-m;
      border: 0;
      border-right: 2px solid $color-blue-200;
      border-radius: 8px 0 0 8px;
      background-color: $color-blue-100;

      &.u-standalone {
        border: 0;
        border-radius: 8px;
      }
    }
    
    .c-wizard__container-progress {
      padding: $spacing-l $spacing-l $spacing-l 0;
    }

  }

}

// Progress bar

.c-wizard__progressbar {
  position: relative; // edit JS for inschrijvingswizard v4

  &-step {

    &:before {
      line-height: 1.25rem;
      position: absolute;
      z-index: -1; // edit JS for inschrijvingswizard v4
      left: 12px; // edit JS for inschrijvingswizard v4
      display: block;
      width: $spacing-xxs;
      height: 4rem; // edit JS for inschrijvingswizard v4
      margin-left: 0;
      content: '';
      background-color: $color-blue-300;
    }

    &:last-child:before {
      // edit JS for inschrijvingswizard v4
      height: 0;
    }


    &-indicator {
      &:before {
        line-height: 1.25rem;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: $spacing-s;
        content: '';
        border: 4px solid $color-blue-300;
        border-radius: 100%;
        background-color: $color-white;
      }

      &.-active {
        &:before {
          border-color: $color-blue-500;
          background-color: $color-blue-500;
        }

        span {
          // edit JS for inschrijvingswizard v4
          color: $color-blue;
        }
      }

      &.-done {
        &:before {
          font-family: icons;
          content: char-from-code($icon-check);
          color: $color-blue-500;
          background-color: $color-blue-300;
        }
      }

      span {
        // edit JS for inschrijvingswizard v4
        font-weight: 500;
        position: absolute;
      }
    }

  }

}

@include screenMedium {
  .c-wizard {
    .c-wizard__container {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      .c-wizard__container-form {
        border-radius: 0;
      }
    }
  }
}

@include screen {
  .c-wizard {
    & > .o-wrapper {
      margin: $spacing-m $spacing-m;
    }
    .c-wizard__container {
      .c-wizard__container-form {
        border-right: 0;
      }
    }
  }
}

// BEMIT refactor + inschrijvingswizard v4 edits by JS