@media print {

  header,
  .navbar-burger {
    display: none;
  }

  footer,
  .footer {
    display: none;
  }


  img {
    display: none;
  }

  /* Typografie */

  body {
    font: 12pt;
  }

  html {
    font-size: 12pt;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16pt;
  }

  a::after {
    content: ' ('attr(href) ') ';
    text-decoration: underline;
  }

  /* Block specific */
  /* Inschrijven niewusbrief */
  .inschrijven-nieuwsbrief {
    display: none;
  }

  .inschrijven-nieuwsbrief-sidebar {
    display: none;
  }

  /* FAQ-block */


}