.news-block {

  article {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $color-grey-500;

    p {
      margin-bottom: 1.5rem;
    }
  }

}