.c-personal-widget-container {
  @include tablet {
    //border-bottom: 1px solid $color-grey-500;

    //row-gap: 0;

    &.-no-border\@tablet {
      border-bottom: 0;
    }
  }
}

.c-personal-widget {
  @include flexbox;
  overflow: visible;
  padding: $spacing-m;
  text-decoration: none;
  color: $color-grey-900;
  border-radius: 6px;

  align-self: start;

  &:hover {
    text-decoration: none;
    color: $color-grey-900;
    background-color: $color-blue-100;

    .c-personal-widget__title {
      color: $color-blue-500;
    }

  }

  &:visited {
    color: $color-grey-900;
  }

  &.has-error {
    .c-personal-widget__title {
      color: $color-indigo-900;
    }

    .c-personal-widget__icon {
      color: $color-grey-700;
    }
    &:hover .c-personal-widget__title {
      color: $color-blue-500;
    }
  }     

  &.has-loading {
    &:hover .c-personal-widget__title {
      color: $color-grey-700;
    }
  }

  &.has-clickable-list {
    &:hover {
      background-color: transparent;
    }
  }

  &__body {
    @include tablet {
      margin-top: 4px;
    }
    flex: 1;
    margin-top: .4rem;
    margin-left: $spacing-s;

    &.-ghost-icon {
      margin-left: 3.5rem;
    }
  }

  &__header {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    @include tablet {
      padding-bottom: 0;
      border-bottom: 0;
    }
    padding-bottom: $spacing-s;
    border-bottom: 1px solid $color-grey-500;
  }

  &__title {
    @include flexbox;
    @include align-items(center);
  }

  &__icon {
    font-size: 42px;
    width: 42px;
    color: $color-blue-500;
  }

  &__header-arrow {
    font-size: 2rem;
    color: $color-blue-500;
  }

  &__list {
    padding: 0;
  }

  &__list-item {

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list-link {
    font-weight: 500;
    display: block;

    &:before {
      font-weight: 500;
    }
  }

  &__item-info {
    margin-left: $spacing-m;
    color: $color-grey-800;
  }

  &__sub-category {
    margin-top: $spacing-m;
  }
}

.c-personal-widget.has-clickable-list {
  @include tablet {
    .c-personal-widget__body {
      margin-left: 0;
    }

    .c-personal-widget__icon {
      position: absolute;
    }

    .c-personal-widget__header {
      //margin-bottom: $spacing-s;
      padding-bottom: $spacing-s;
      border-bottom: 1px solid $color-grey-500;
    }

    .c-personal-widget__title {
      margin-left: 2.7rem;
    }

  }
  .c-personal-widget__header-arrow {
    display: none;
  }

  .c-personal-widget__title {
    color: $headings-color;
  }
}

@include screen {
  .c-personal-widget {
    &__icon {
      position: absolute;
    }

    &__body {
      margin-left: 0;
    }

    &__title {
      margin-left: 3.5rem;
    }
  }
}

@include tablet {
  .c-personal-widget {
    padding: $spacing-m 0;
    // Hack because the css of .c-personal-widget-container was not possible for Drupal to implement right away. TODO: add modifier class on o-grid-block in Drupal to remove the row-gap on tablet!
    padding-bottom: 0;
    // End of hack
    border-top: 1px solid $color-grey-500;
    border-radius: 0;

    &.-no-border\@tablet {
      border-top: 0;
    }

    &__body {
      margin-top: 0;
      margin-left: $spacing-s;

      &.-ghost-icon {
        margin-left: 0;
      }
    }

    &__header {
      margin-left: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &__title {
      margin-left: 0;
    }

    &__icon {
      font-size: 28px;
      position: unset;
      width: 32px;

      &.-error {
        color: $color-grey-700;
      }
    }

    &__list {
      display: none;
    }

    &__header-arrow {
      font-size: 2rem;
      width: 32px;
      height: 32px;
      color: $color-blue-500;
      border: 1px solid $color-blue-300;
      border-radius: $spacing-xxs;
      background-color: $color-blue-100;

      &:before {
        margin-left: $spacing-xs;
      }
    }
  }
}