//sass-lint:disable-all no-color-literals no-color-hex
.c-page-header {
  padding-top: 0.01rem;
  padding-bottom: $spacing-l;
  background: linear-gradient(269.95deg, rgba(12, 136, 126, .8) -10.24%, rgba(73, 180, 170, .8) 99.55%);

  &.-home {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, #49B4AA 0%, #0C887E 100%);
  }

  &.-jobs {
    background: radial-gradient(62.1% 385.9% at 73.27% 50%, #2D89CC 0%, #004475 100%);
  }

  &.-opleidingen {
    background: radial-gradient(62.1% 385.9% at 73.27% 50%, #FDB85E 0%, #E27D10 100%);
  }

  &.-orienteren {
    background: radial-gradient(62.07% 385.69% at 73.23% 50%, #7F2AA7 0%, #48006A 100%);
  }

  &__textblock {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    padding: $spacing-l 0;
    color: $color-white;
    @include tablet {
      padding: $spacing-m 0;
    }
  }

  &__imageblock {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(flex-end);
    @include screen {
      display: none;
    }
  }

  &__image {
    width: auto;
    max-width: 100%;
    max-height: 300px;
    margin-top: $spacing-s;
  }

  &__action-link-list {
    margin-top: $spacing-s;
  }

  &__widget-container {
    margin-top: -6rem;
  }

}

@include screen() {
  .c-page-header {
    padding-bottom: 0;
  }
}

.c-page-header.-thin {
  .c-page-header__imageblock {
    display: none;
  }

  .c-page-header__intro-text {
    margin-bottom: 0;
  }

  .c-page-header__action-link-list {
    display: none;
  }
}

.c-page-header.-thin.-home {
  .c-page-header__wrapper-sidebar {
    row-gap: 0;
  }
}