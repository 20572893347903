.c-profile-widget {
  &__header {
    @include flex-direction(column);
    @include align-items(flex-start);
    border: 0;
  }

  &__title {
    margin-bottom: $spacing-s;
  }

  &__column {
    &:hover {
      background-color: transparent;

      .c-profile-widget__title {
        color: $headings-color;
      }
    }
  }

  &__inschrijvingsstatus {
    margin-top: 3rem;
  }
}

@include screen {
  .c-profile-widget {
    &__title {
      margin-left: 3.5rem;
      word-break: break-all;
    }
  }
}
@include tablet {
  .c-profile-widget {
    grid-gap: 0;

    &__column {
      border-top: 0;
    }

    &__header {
      border-bottom: 0 !important;
    }

    &__body {
      margin-top: .4rem;
    }

    &__inschrijvingsstatus {
      margin-top: 0;
    }
  }
}