//Action links

a[class*=action-] {
  position: relative;
  text-decoration: none;

  alt: '';

  &:not(.c-btn, .c-round-btn, .o-inverted-link):visited {
    text-decoration: none;
    color: $color-blue-500;
  }

  &:not(.c-btn, .c-round-btn, .o-inverted-link, .c-pill):hover {
    text-decoration: none;
    color: darken($color-blue-500, 15%);
  }

  &:focus {
    outline: 2px solid transparentize($color-blue-500, .25);
  }

}

.c-action-link-list {
  @include reset-list;
  @include flexbox;
  @include flex-direction(column);

  &.-horizontal {
    @include flexbox;
    @include flex-direction(row);
    @include tablet {
      @include flex-direction(column);

      .c-action-link-list__item {
        margin-right: 0;
      }
    }

    .c-action-link-list__item {
      margin-right: $spacing-s;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item {
    margin: 0;

  }

  &__link {
    display: inline-block;
    padding: $spacing-xs 0;

    &.-horizontal {
      @include tablet {
        margin-right: 0;
      }
      margin-right: $spacing-s;
      padding: $spacing-xs 0;
    }
  }

  &__item:last-child {
    .c-action-link-list__link {
      padding-bottom: 0;

      &.-horizontal {
        @include tablet {
          padding-bottom: 0;
        }
        margin-right: 0;
      }
    }
  }

}

.icon-after:after {
  font-family: 'icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;

  speak: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.action-lone {
  display: inline-block;
  overflow: hidden;
  width: 1em;
  white-space: nowrap;
}


@include phone() {
  .action-lone\@phone {
    display: inline-block;
    overflow: hidden;
    width: 1em;
    padding: .4rem;
    white-space: nowrap;
  }
}

@include tablet() {
  .action-lone\@tablet {
    display: inline-block;
    overflow: hidden;
    width: 1em;
    padding: .4rem;
    white-space: nowrap;
  }
}

.action-download:before {
  @extend .icon;
  @extend .icon-attachment;
  padding-right: .3em;
  vertical-align: middle;
}

.action-go:before {
  @extend .icon;
  @extend .icon-arrow-right;
  padding-left: .3em;
  vertical-align: middle;
}

.action-save-alt:before {
  @extend .icon;
  @extend .icon-save;
  padding-left: .3em;
  vertical-align: middle;
}


.action-two-arrows-right-left:before {
  @extend .icon;
  @extend .icon-two-arrows-right-left;
  padding-right: .3em;
  vertical-align: middle;
}

.action-attachment:before {
  @extend .icon;
  @extend .icon-attachment;
  padding-right: .3em;
  vertical-align: middle;
}

.action-color-pallete:before {
  @extend .icon;
  @extend .icon-color-pallete;
  padding-right: .3em;
  vertical-align: middle;
}

.action-file:before {
  @extend .icon;
  @extend .icon-file;
  padding-right: .3em;
  vertical-align: middle;
}

.action-look:before {
  @extend .icon;
  @extend .icon-eye;
  padding-right: .3em;
  vertical-align: middle;
}

.action-look-strike:before {
  @extend .icon;
  @extend .icon-eye-strike;
  padding-right: .3em;
  vertical-align: middle;
}

.action-add:before {
  @extend .icon;
  @extend .icon-plus;
  padding-right: .3em;
  vertical-align: middle;
}


.action-edit:before {
  @extend .icon;
  @extend .icon-edit;
  padding-right: .3em;
  vertical-align: middle;
}

.action-filter:before {
  @extend .icon;
  @extend .icon-filter;
  padding-right: .3em;
  vertical-align: middle;
}

.action-filter-delete:before {
  @extend .icon;
  @extend .icon-filter-full-remove;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reload:before {
  @extend .icon;
  @extend .icon-reload;
  padding-right: .3em;
  vertical-align: middle;
}

.action-copy:before {
  @extend .icon;
  @extend .icon-copy-doc;
  padding-right: .3em;
  vertical-align: middle;
}

.action-user:before {
  @extend .icon;
  @extend .icon-user;
  padding-right: .3em;
  vertical-align: middle;
}

.action-warning:before {
  @extend .icon;
  @extend .icon-warning;
  padding-right: .3em;
  vertical-align: middle;
}


.action-menu:before {
  @extend .icon;
  @extend .icon-menu;
  padding-right: .3em;
  vertical-align: middle;
}

.action-bulb:before {
  @extend .icon;
  @extend .icon-bulb;
  padding-right: .3em;
  vertical-align: middle;
}

.action-mail:before {
  @extend .icon;
  @extend .icon-mail;
  padding-right: .3em;
  vertical-align: middle;
}

.action-certified:before {
  @extend .icon;
  @extend .icon-certified;
  padding-right: .3em;
  vertical-align: middle;
}

.action-study:before {
  @extend .icon;
  @extend .icon-study;
  padding-right: .3em;
  vertical-align: middle;
}


.action-education:before {
  @extend .icon;
  @extend .icon-education;
  padding-right: .3em;
  vertical-align: middle;
}

.action-save:before {
  @extend .icon;
  @extend .icon-save-alt;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reopen:before {
  @extend .icon;
  @extend .icon-arrow-up-bold;
  padding-right: .3em;
  vertical-align: middle;
}

.action-threshold:before {
  @extend .icon;
  @extend .icon-threshold;
  position: relative;
  bottom: 2px;
  margin-right: 7px;
}

.action-trashcan:before {
  @extend .icon;
  @extend .icon-trash;
  padding-right: .3em;
  vertical-align: middle;
}

.action-delete:before {
  @extend .icon;
  @extend .icon-error;
  padding-right: .3em;
  vertical-align: middle;
}

.action-delete--red {
  color: $color-red-700;

  &:hover {
    color: $color-red;
  }
}

.action-cancel:before {
  @extend .icon;
  @extend .icon-x;
  padding-right: .3em;
  vertical-align: middle;
}

.action-compare:before {
  @extend .icon;
  @extend .icon-compare;
  padding-right: .5em;
  vertical-align: middle;
}

.action-submit:before {
  @extend .icon;
  @extend .icon-check;
  padding-right: .3em;
  vertical-align: middle;
}

.action-settings:before {
  @extend .icon;
  @extend .icon-cog;
  padding-right: .3em;
  vertical-align: middle;
}

.action-reply:before {
  @extend .icon;
  @extend .icon-reply;
  padding-right: .3em;
  vertical-align: middle;
}

.action-print:before {
  @extend .icon;
  @extend .icon-printer;
  padding-right: .3em;
  vertical-align: middle;
}

.action-export:before {
  @extend .icon;
  @extend .icon-export;
  padding-right: .3em;
  vertical-align: middle;
}


.action-expand-down:before {
  @extend .icon;
  @extend .icon-expand-down-small;
  padding-right: .3em;
  vertical-align: middle;
}

.action-book-open:before {
  @extend .icon;
  @extend .icon-book-open;
  padding-right: .3em;
  vertical-align: middle;
}




.action-expand-up:before {
  @extend .icon;
  padding-right: .3em;
  content: char-from-code($icon-expand-up-small);
  vertical-align: middle;
}


.action-search:before {
  @extend .icon;
  @extend .icon-search;
  padding-right: .3em;
  vertical-align: middle;
}

.action-back:before {
  @extend .icon;
  @extend .icon-arrow-left;
  padding-right: .3em;
  vertical-align: middle;
}

.action-lock:before {
  @extend .icon;
  @extend .icon-lock;
  padding-right: .3em;
  vertical-align: middle;
}

.action-show:before {
  @extend .icon;
  @extend .icon-arrow-down;
  padding-right: .3em;
  vertical-align: middle;
}

.action-hide:before {
  @extend .icon;
  @extend .icon-arrow-up;
  padding-right: .3em;
  vertical-align: middle;
}

.action-add-group:before {
  @extend .icon;
  @extend .icon-group-add;
  padding-right: .3em;
  vertical-align: middle;
}

.action-info:before {
  @extend .icon;
  @extend .icon-info;
  padding-right: .3em;
  vertical-align: middle;
}

.action-date:before {
  @extend .icon;
  @extend .icon-date;
  padding-right: .3em;
  vertical-align: middle;
}

.action-circle-add:before {
  @extend .icon;
  @extend .icon-circle-add;
  padding-right: .3em;
  vertical-align: middle;
}

.action-facebook:before {
  @extend .icon;
  @extend .icon-social-facebook;
  padding-right: .3em;
  vertical-align: middle;
}

.action-twitter:before {
  @extend .icon;
  @extend .icon-social-twitter;
  padding-right: .3em;
  vertical-align: middle;
}

.action-linkedin:before {
  @extend .icon;
  @extend .icon-social-linkedin;
  padding-right: .3em;
  vertical-align: middle;
}

.action-star:before {
  @extend .icon;
  @extend .icon-star;
  padding-right: .3em;
  vertical-align: middle;
}

.action-cycle:before {
  @extend .icon;
  @extend .icon-cycle;
  padding-right: .3em;
  vertical-align: middle;
}

.action-list:before {
  @extend .icon;
  @extend .icon-list;
  padding-right: .3em;
  vertical-align: middle;
}

.action-time:before {
  @extend .icon;
  @extend .icon-time;
  padding-right: .3em;
  vertical-align: middle;
}

.action-history:before {
  @extend .icon;
  @extend .icon-history;
  padding-right: .3em;
  vertical-align: middle;
}

.action-quick-menu:before {
  @extend .icon;
  @extend .icon-ellipsis;
  vertical-align: middle;
}

.action-verslag:before {
  @extend .icon;
  @extend .icon-note-text-pencil;
  vertical-align: middle;
}

.action-jobs:before {
  @extend .icon;
  @extend .icon-briefcase;
  padding-right: .3em;
  vertical-align: middle;
}

.action-job:before {
  @extend .icon;
  @extend .icon-job;
  padding-right: .3em;
  vertical-align: middle;
}

.action-city:before {
  @extend .icon;
  @extend .icon-marker;
  padding-right: .3em;
  vertical-align: middle;
}

.action-select-all:before {
  @extend .icon;
  @extend .icon-multitick;
  padding-right: .3em;
  vertical-align: middle;
}

.action-deselect-all:before {
  @extend .icon;
  @extend .icon-multitick-checked;
  padding-right: .3em;
  vertical-align: middle;
}


.action-call:before {
  @extend .icon;
  @extend .icon-phone;
  padding-right: .3em;
  vertical-align: middle;
}


.action-envelope:before {
  @extend .icon;
  @extend .icon-envelope;
  padding-right: .3em;
  vertical-align: middle;
}


.action-close:before {
  @extend .icon;
  @extend .icon-cross;
  padding-right: .3em;
  vertical-align: middle;
}

.action-group:before {
  @extend .icon;
  @extend .icon-group;
  padding-right: .3em;
  vertical-align: middle;
}

.action-megaphone:before {
  @extend .icon;
  @extend .icon-megaphone;
  padding-right: .3em;
  vertical-align: middle;
}

.action-marker:before {
  @extend .icon;
  @extend .icon-marker;
  padding-right: .3em;
  vertical-align: middle;
}

.action-propeller:before {
  @extend .icon;
  @extend .icon-smallstar;
  padding-right: .3em;
  vertical-align: middle;
}

.action-computer:before {
  @extend .icon;
  @extend .icon-computer;
  padding-right: .3em;
  vertical-align: middle;
}

.action-chat:before {
  @extend .icon;
  @extend .icon-chat;
  padding-right: .3em;
  vertical-align: middle;
}







// Action links with icon behind text ====================================================================================


.action-warning-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-warning);
  vertical-align: middle;
}

.action-more-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-right);
  vertical-align: middle;
}

.action-jobs-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-briefcase);
  vertical-align: middle;
}

.action-city-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-marker);
  vertical-align: middle;
}

.action-show-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-down);
  vertical-align: middle;
}

.action-link:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-link);
  vertical-align: middle;
}

.action-less-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-up);
  vertical-align: middle;
}

.action-go-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-arrow-right);
  vertical-align: middle;
}

.action-go-long:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-after);
  vertical-align: middle;
}

.action-trash-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-cross);
  vertical-align: middle;
}

.action-trashcan-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-trash);
  vertical-align: middle;
}

.action-edit-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-edit);
  vertical-align: middle;
}

.action-look-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-eye);
  vertical-align: middle;
}


.action-pdf-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-pdf);
  vertical-align: middle;
}

.action-new-tab-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-new-tab);
  vertical-align: middle;
}

.action-download-file-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-download-file);
  vertical-align: middle;
}

.action-delete-after:after {
  // fixed by JS, wrong css
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-error);
  vertical-align: middle;
}

.action-close-after:after {
  @extend .icon-after;
  padding-left: .3em;
  content: char-from-code($icon-cross);
  vertical-align: middle;
}

