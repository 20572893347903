.c-divider {
  width: 100%;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: $color-grey-500;
}

// TO DELETE AFTER DRUPAL CHANGES
.divider {
  width: 100%;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: $color-grey-500;
}