.c-pill {
  
  line-height: 1.75rem;
  display: inline-block;
  padding: 0 .625rem;
  text-decoration: none;
  color: $color-grey-800;
  border: 1px solid $color-grey-600;
  border-radius: 25px;
  background-color: $color-white;
  
  &::after {
    margin-bottom: 2px;
    padding-left: $spacing-xs;
    color: $color-grey-600;
  }

  &:hover {
    text-decoration: none;
    color: $color-grey-900;
    border: 1px solid $color-grey-900;
    background-color: $color-grey-100;
    &::after {
      color: $color-grey-900;
    }
  }
}