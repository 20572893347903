/* check if the appearance none attr is supported. 
If not, default browser styling will be used. */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {

  .checkbox {
    /* used to make sure tht the second line of a label 
    starts at the same point as the first line (so not under the checkbox) */
    @extend .radiobutton;

    &__sub-label {
      position: relative;
      margin-left: 24px;
      padding-right: 24px;
      color: $color-grey-700;

      &.-hidden\@phone {
        @include phone() {
          display: none;
        }
      }

      &.-expanded\@phone:after {
        @include phone() {
          line-height: 1.25rem;
          position: absolute;
          z-index: -1;
          top: 0;
          right: 9px;
          display: block;
          width: .06rem;
          height: 100%;
          margin-right: 2px;
          content: '';
          background-color: $color-indigo-900;
        }
      }
    }

    &__show-sub-label {
      position: absolute;
      right: 0;
    }
  }

  /* general styling checkbox - absolute positioned so that a multi-line label
  always starts at the beginning of the label and not under the checkbox */
  input[type='checkbox'] {
    @extend .input-type-radio;
    transition: background .3s, border-color .3s, box-shadow .2s;
    border-radius: 2px;

    /* styling for the checkmark in the checkbox*/
    &:after {
      display: block;
      width: 3px;
      height: 7px;
      margin-top: 1px;
      margin-left: 4px;
      content: ' ';
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
      border: solid $color-white;
      border-top: 1px;
      border-left: 1px;
      border-radius: 1px;
    }

    /* different states checkbox */
    &:checked {
      border-color: $color-blue-500;
      background-color: $color-blue-500;

      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: 2px solid $color-blue-400;
    }

    &:disabled {
      border-color: $color-grey-300;

      &:checked {
        border-color: $color-grey-500;
        background-color: $color-grey-500;

        &:after {
          opacity: 1;

        }
      }

    }
  }


}



/* options - inset */



/* layout - kolommen */