.c-image-container {
  @include flexbox;

  &.-top {
    @include align-items(flex-start);
  }

  &.-bottom {
    @include align-items(flex-end);
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}


.c-image-one-col {
  &__image-box {
    overflow: hidden;
    height: 12rem;
    margin-bottom: $spacing-s;
  }

  &__image {
    max-width: 100%;

    object-fit: cover;
  }
}

.c-image-16-9 {
  &__image-box {
    overflow: hidden;
    width: 100%;
  }
}