.c-step-block {
  padding-left: 0;
  list-style: none;
  counter-reset: counter;

  &__item {
    counter-increment: counter;
  }

  &__item.-horizontal {
    @include flexbox();

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__item:before {
    font-size: $font-size-h1;
    font-weight: 500;
    margin-top: -.2rem;
    margin-right: 2rem;
    margin-bottom: .5rem;
    content: counter(counter);
    color: $headings-color;
  }
}
