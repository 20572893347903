// Extend for all dialogbox icons
.dialogbox-icon {
  font-family: icons;
  font-size: 1.625rem;
  position: absolute;
  top: 2rem;
  left: 1.5rem;
}

@mixin dialogbox($type) {
  position: fixed;
  z-index: 125;
  top: 50%;
  left: 50%;
  overflow: auto;
  box-sizing: border-box;
  width: 435px;
  max-width: 90vw;
  max-height: 90vh;
  padding: 1.5rem 1.5rem 1.5rem 4.5rem;
  transform: translate(-50%, -50%);
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
  background-color: $color-white;
  box-shadow: $transparent-black-40 5px 5px 20px;

  &::after {
    @extend .dialogbox-icon;
  }

  @if $type==danger {
    border-color: $color-red-900;

    h1 {
      color: $color-red-900;
    }

    .btn.-confirm {
      color: $color-white;
      background-color: $color-red-900;
    }

    .btn.ghost {
      color: $color-red-900;
    }

    &::after {
      content: char-from-code($icon-warning);
      color: $color-red-900;
    }
  } @else if $type==warning {
    border-color: $color-orange-a500;

    h1 {
      color: $color-orange-a500;
    }

    .btn.-confirm {
      color: $color-white;
      background-color: $color-orange-a500;
    }

    .btn.ghost {
      color: $color-orange-a500;
    }

    &::after {
      content: char-from-code($icon-warning);
      color: $color-orange-a500;
    }
  } @else {
    border-color: $color-blue-700;

    h1 {
      color: $color-blue-700;
    }

    .btn.-confirm {
      color: $color-white;
      background-color: $color-blue-700;
    }

    &::after {
      content: char-from-code($icon-info);
      color: $color-blue-700;
    }
  }

}

.dialogbox {
  @extend .u-hs-overlay;
}

// Regular dialogbox
.dialogbox-info {
  @include dialogbox(info);
}

.dialogbox-warning {
  @include dialogbox(warning);
}

.dialogbox-danger {
  @include dialogbox(danger);
}