.c-intro-block {

  &__title {
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
  }

}

/*
// TO DELETE AFTER DRUPAL CHANGES
a.intro-block {
  h1 {
    margin-top: $spacing-xs;
    margin-bottom: $spacing-xs;
    
  }
  p {
    font-weight: 300;
    font-size: $font-size-h3;
    line-height: $baseline*1.4; 
    margin-bottom: $baseline/2;
    color: $color-indigo-900;
  }
}
// END to TO DELETE AFTER DRUPAL CHANGES
  */