.c-bidk-widget {
  @include flexbox();
  @include flex-direction(row);
  @include flex-wrap(wrap);

  &__logo {
    @include flex-basis(14.28%);
    position: relative;
    box-sizing: border-box;
    padding: 2rem 1rem;
    text-align: center;
    border: 1px solid $color-white;

    &:hover {
      .c-bidk-widget__img {
        filter: none;
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .2;
    background-color: $color-blue-300;
  }

  &__img {
    max-width: 100%;
    max-height: 3rem;

    filter: grayscale(1);
  }
}

@media (max-width: $screen-small) {
  .c-bidk-widget {
    &__logo {
      @include flex-basis(25%);
    }
  }
}

@media (max-width: $phone) {
  .c-bidk-widget {
    &__logo {
      @include flex-basis(50%);
    }
  }
}