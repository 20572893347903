.c-evenementen-block {

  &__item {
    @include flexbox;
  }

  &__date {
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    display: inline-flex;
    min-width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    padding: .5rem;
    border: 1px solid $color-grey-200;
    border-radius: 10px;
    background-color: $color-grey-100;

    flex-shrink: 0;
  }

  &__day {
    font-size: $font-size-h3;
    font-weight: 500;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    color: $color-indigo-900;
  }


  &__month {
    font-weight: 500;
    margin-bottom: 0;
  }

  &__title {
    font-weight: 500;
  }

  &__location {
    position: relative;
    padding-left: 1.5rem;

    &:before {
      font-family: icons;
      position: absolute;
      left: 0;
      content: '\E02C';
      color: $color-grey-700;
    }
  }

  &__online {
    position: relative;
    padding-left: 1.5rem;

    &:before {
      font-family: icons;
      position: absolute;
      left: 0;
      content: '\E0AA';
      color: $color-grey-700;
    }
  }
}