@mixin fade-background($from-color: $transparent-white-00, $to-color: $color-white) {
  background: linear-gradient($from-color 50%, $to-color);
}

.c-card {
  @include screen() {
    padding: $spacing-s;
  }
  position: relative;
  max-width: 100%;
  padding: $spacing-m;
  word-break: break-word;
  border: 1px solid $color-grey-500;
  border-radius: $spacing-xs;
  background-color: $color-white;

  &.has-hover, .has-hover:hover, .has-hover:visited {
    text-decoration: none;
    color: inherit;
  }

  &.has-hover:hover {
    cursor: pointer;
    transform: translateY(-5px) scale(1.005) translateZ(0);
    border-color: $color-blue-500;
    -webkit-box-shadow: 0 16px 24px 2px $transparent-black-05, 0 6px 30px 5px $transparent-black-05, 0 8px 10px -7px $transparent-black-10;
    box-shadow: 0 16px 24px 2px $transparent-black-05, 0 6px 30px 5px $transparent-black-05, 0 8px 10px -7px $transparent-black-10;
  }

  &__image-box { 
    overflow: hidden;
    height: 12rem;
    margin-bottom: $spacing-m; 
  }

  &__image-box.-negative-margin {
    @include screen() {
      margin-top: -$spacing-s;
      margin-right: -$spacing-s;
      margin-left: -$spacing-s;
    }
    margin-top: -$spacing-m;
    margin-right: -$spacing-m;
    margin-left: -$spacing-m;
    border-radius: $spacing-xs $spacing-xs  0 0;
  }

  &__logo-box {
    max-width: 100%;
    height: 3.5rem;
    margin-bottom: $spacing-m;
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__logo {
    //width: 100%;
    height: 100%;

    object-fit: contain;
  }

  &__star-box {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
  
  &__star {
    color: $color-grey;
  }

  &__star.-is-active, &__star:hover {
    color: $color-yellow;
  }

  &__title {
    @include flex-wrap(wrap);
    @include align-items(flex-start);
    @include screen() {
      width: 100%;
    }
    margin-bottom: $spacing-m;
  }

  &__clickable-header {
    display: inline;
    margin-bottom: $spacing-s;
    color: $color-blue-500;
  }

  &__tag {
    margin-top: $spacing-xs;
    margin-left: $spacing-xs;
  }

  &__content {
    position: relative;
    word-break: break-word; // 10/02 edit JS

    &.-text-fade {
      position: relative;
      overflow: hidden;
      height: 15rem;
  
      &:before {
        @include fade-background();
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
      }
    }
  }

  &__body-text {
    margin-bottom: $spacing-m;
  }

  &__bottom-container {
    width: 100%s;
  }

}


