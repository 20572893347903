.c-alert {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(flex-start);
  margin-bottom: $spacing-m;
  padding: $spacing-s;
  border: 1px solid;
  border-radius: 5px;

  .icon-close {
    cursor: pointer;
    text-decoration: none;
  }

  &:before {
    @extend .icon;
    margin-top: .25rem; // fix alert icon alignment
    margin-right: $spacing-s;
  }

  .c-alert__content {
    flex-grow: 1;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
    color: inherit;

    text-decoration-color: inherit;

    &:visited {
      color: inherit;

      text-decoration-color: inherit;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &.-success {
    color: $color-green-900;
    border-color: transparentize($color: $color-green-900, $amount: .85);
    background-color: $color-lightgreen-200;

    h4 {
      color: $color-green-900;
    }

    &:before {
      @extend .icon-status;
    }
  }

  &.-info {
    color: $color-blue-900;
    border-color: transparentize($color: $color-blue-900, $amount: .85);
    background-color: $color-blue-200;

    h4 {
      color: $color-blue-900;
    }

    &:before {
      @extend .icon-info;
    }
  }

  &.-warning {
    color: $color-orange-900;
    border-color: transparentize($color: $color-orange-900, $amount: .85);
    background-color: $color-orange-300;

    h4 {
      color: $color-orange-900;
    }

    &:before {
      @extend .icon-warning;
    }
  }

  &.-error {
    color: $color-red-700;
    border-color: transparentize($color: $color-red-900, $amount: .85);
    background-color: $color-red-200;

    h4 {
      color: $color-red-700;
    }

    &:before {
      @extend .icon-warning;
    }
  }
}

.c-fixed-alert-container {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  &.-wide {
    width: 1300px;
    .c-alert {
      width: auto;
    }
  }
  &.-beneath-navbar {
    margin: 136px auto 0;
    .c-alert {
      margin: 0 auto $spacing-s;
    }
  }
  .c-alert {
    width: 650px;
    margin-top: $spacing-s;
    margin-bottom: 0;
    &.-wide {
      box-sizing: border-box;
      width: 1300px;
    }
  }
}

@media screen and (max-width: 700px) { // this might seem random but it's because the normal alert is also 650px wide and we need 50px extra for prettyness
  .c-fixed-alert-container {
    width: 100%;
    .c-alert {
      width: auto;
      margin: $spacing-s $spacing-m 0 $spacing-m;
    }
    &.-beneath-navbar {
      .c-alert {
        width: auto;
        margin: 0 $spacing-m;
      }
    }
    &.-wide {
      .c-alert {
        width: auto;
        margin: 0 $spacing-s;
      }
    }
  }
}

@include screen {
  .c-fixed-alert-container.-beneath-navbar {
    margin: 96px auto 0;
  }
}

@media screen and (max-width: 1350px) { // this might seem random but it's because the wide alert is also 1300px wide and we need 50px extra for prettyness
  .c-fixed-alert-container {
    &.-wide {
      width: 100%;
      .c-alert {
        width: auto;
        margin: $spacing-s $spacing-m 0 $spacing-m;
      }
    }
    &.-beneath-navbar {
      .c-alert.-wide {
        width: calc(100vw - 5rem);
        margin: 0 $spacing-m;
      }
    }
    &.-beneath-navbar.-wide {
      .c-alert {
        width: auto;
        margin: 0 $spacing-m;
      }
    }
    .c-alert.-wide {
      box-sizing: unset;
      width: calc(100vw - 5rem);
      margin: $spacing-s $spacing-m 0 $spacing-m;
    }
  }
}