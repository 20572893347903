.field-file-upload {

  label {
    width: 50%;
    color: $color-blue-500;

    &:before {
      @extend .icon;
      @extend .icon-circle-add;
      padding-right: $spacing-xxs;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
      color: darken($color-blue-500, 15%);
    }
  }

  span {
    margin-left: $spacing-m;
  }

}