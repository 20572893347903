.c-round-btn {
  display: inline-block;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 1.75rem;

  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:before {
    margin-top: .6875rem;
    margin-right: 2.5rem;
    margin-left: .6875rem;
  }
}

// default color buttos
.c-round-btn, .c-btn {
  &.-primary {
    color: $color-white;
    border-color: $color-blue-500;
    background-color: $color-blue-500;

    &:hover, &:active {
      color: $color-white;
      background-color: $color-blue-700;
    }

    &:focus {
      color: $color-white;
      border-color: $color-blue-900;
      background-color: $color-blue-500;
    }
  }

  &.-secundary:not(.-green) {
    color: $color-blue-500;
    border-color: $color-blue-500;
    background-color: $color-white;

    &:hover, &:active {
      color: $color-blue-500 !important;
      background-color: $color-blue-100;
    }


    &:focus {
      color: $color-blue-500 !important;
      border-color: $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }
  }

  &.-tertiary {
    color: $color-indigo-900;
    border-color: $color-indigo-300;
    background-color: $color-white;


    &:hover, &:active {
      color: $color-indigo-900;
      background-color: $color-blue-100;
    }

    &:focus {
      border-color: $color-indigo-900;
      background-color: $color-blue-100;
    }


  }

  &.-ghost {
    color: $color-blue-500;
    border: 0;
    background-color: transparent;


    &:hover, &:active {
      color: $color-blue-500;
      background-color: $color-blue-200;
    }

    &:focus {
      border-color: $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }
  }

  &.-ninja {
    padding: 0;
    color: $color-link;
    border: 0;
    background: transparent;

    &:focus {
      padding: 0;
      border: 0;
    }

    &:hover {
      color: $color-hover;
    }

    &[class*=' icon-'] {
      position: relative;
      min-width: 0;
      
      &:after {
        position: absolute;
        top: -25%;
        left: -50%;
        width: 200%;
        height: 150%;
        content: '';
        cursor: pointer;
      }
    }

    &.-blend {
      color: inherit;
    }
  }

  &.-light {
    font-weight: 400;
    border-color: $color-blue-300;
    background-color: $color-blue-100;

    &:focus {
      border-color: $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }

    &:hover, &:active {
      color: darken($color-blue-500, 15%);
      background-color: $color-blue-200;
    }
  }

  // styling of a group of more than 1 button
  .o-btn-group & {
    margin: $spacing-s $spacing-m 0 0;

    &:last-child {
      margin-right: 0;
    }

  }
}

// sizes for buttons
.c-btn {
  font-family: $brand-font-regular;
  font-weight: 500;
  line-height: 1.625rem;
  display: inline-block;
  min-width: 2.876rem;
  padding: .688rem 1.438rem; //12 24 minus the added border (2px)
  cursor: pointer;
  transition: (all .1s);
  text-align: center;
  text-decoration: none;
  border-width: .063rem;
  border-style: solid;
  border-radius: .321rem;


  &:hover, &:active, &:focus {
    text-decoration: none;
    //color: inherit; // Gives problems when embedded
  }

  &:focus {
    padding: .563rem 1.313rem; //12 24 minus the added border (3px)
    border: .188rem solid;
  }

  &:disabled, .disabled {
    color: $color-grey-800;
    border-color: $color-grey-400;
    background-color: $color-grey-400;
  }

  &.-full-width {
    box-sizing: border-box;
    width: 100%;
  }

  &.-small {
    line-height: 1.562rem;
    padding: .313rem .938rem; //6 16 minus the added border (1px)

    &:focus {
      padding: .188rem .813rem; //6 16 minus the added border (3px)
    }
  }

  &.-mini {
    line-height: 25px;
    padding: .188rem .688rem; //4 12 minus the added border 1px

    &:focus {
      padding: .063rem .563rem; //4 12 minus the added border 3px
    }
  }

  &.-medium {
    padding: .438rem 1.438rem; //8 24 minus the added border 1px

    &:focus {
      padding: .313rem 1.313rem; //8 24 minus the added border 3px
    }
  }

  &.-big {
    padding: 1.188rem 1.938rem; //20 32 minus the added border

    &:focus {
      padding: 1.063rem 1.813rem;
    }
  }

  &.-itsme {
    margin-right: 1rem;
    &::before {
      font-size: 1.5rem;
      margin-right: .5rem;
      color: $color-orange-700;
    }
  }
}


// COLOR VARIANTS
.c-btn.-primary.-green {
  color: $color-white;
  border-color: $color-turquoise-700;
  background-color: $color-turquoise-700;

  &:hover, &:active {
    background-color: $color-turquoise-900;
  }

  &:focus {
    border-color: $color-turquoise-900;
    background-color: $color-turquoise-700;
  }
}

.c-btn.-secundary.-green {
  color: $color-turquoise-700;
  border-color: $color-turquoise-700;
  background-color: $color-white;

  &:hover, &:active {
    background-color: $color-turquoise-200;
  }


  &:focus {
    border-color: $color-turquoise-900;
    background-color: $color-turquoise-200;
  }

}

// styling of a group of more than 1 button
.o-btn-group {
  @include flexbox;
  @include flex-wrap(wrap);
  &:last-child {
    margin-right: 0;
  }
  & > a {
    display: inline-block !important;
    margin: $spacing-s $spacing-m 0 0;
  }
  & > a:last-child {
    margin-right: 0;
  }
}

// TODO: continue refactoring
// TO DELETE AFTER DRUPAL CHANGES

.btn {
  font-family: $brand-font-regular;
  font-weight: 500;
  line-height: 1.625rem;
  display: inline-block;
  min-width: 2.876rem;
  padding: .688rem 1.438rem; //12 24 minus the added border (2px)
  cursor: pointer;
  transition: (all .1s);
  text-align: center;
  text-decoration: none;
  border: .063rem solid;
  border-radius: .321rem;


  &:hover, &:active, &:focus {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    padding: .563rem 1.313rem; //12 24 minus the added border (3px)
    border: .188rem solid;
  }

  &:disabled, .disabled {
    color: $color-grey-800;
    border-color: $color-grey-400;
    background-color: $color-grey-400;
  }

  &.small {
    line-height: 1.562rem;
    padding: .313rem .938rem; //6 16 minus the added border (1px)

    &:focus {
      padding: .188rem .813rem; //6 16 minus the added border (3px)
    }
  }

  &.full-width {
    box-sizing: border-box;
    width: 100%;
  }

  &.mini {
    line-height: 25px;
    padding: .188rem .688rem; //4 12 minus the added border 1px

    &:focus {
      padding: .063rem .563rem; //4 12 minus the added border 3px
    }
  }

  &.medium {
    padding: .438rem 1.438rem; //8 24 minus the added border 1px

    &:focus {
      padding: .313rem 1.313rem; //8 24 minus the added border 3px
    }
  }

  &.big {
    padding: 1.188rem 1.938rem; //20 32 minus the added border

    &:focus {
      padding: 1.063rem 1.813rem;
    }
  }
}

.round-btn {
  display: inline-block;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  white-space: nowrap;
  border-radius: 1.75rem;

  &:before {
    margin-top: .6875rem;
    margin-right: 2.5rem;
    margin-left: .6875rem;
  }

}

.btn, .round-btn {


  &.primary {
    color: $color-white;
    border-color: $color-blue-500;
    background-color: $color-blue-500;

    &:hover, &:active {
      background-color: $color-blue-700;
    }

    &:focus {
      border-color: $color-blue-900;
      background-color: $color-blue-500;
    }
  }


  &.secundary {
    color: $color-blue-500;
    border-color: $color-blue-500;
    background-color: $color-white;

    &:hover, &:active {
      background-color: $color-blue-100;
    }


    &:focus {
      border-color: $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }
  }

  &.tertiary {
    color: $color-indigo-900;
    border-color: $color-indigo-300;
    background-color: $color-white;


    &:hover, &:active {
      background-color: $color-blue-100;
    }

    &:focus {
      border-color: $color-indigo-900;
      background-color: $color-blue-100;
    }


  }

  &.ghost {
    color: $color-blue-500;
    border: 0;
    background-color: transparent;


    &:hover, &:active {
      background-color: $color-blue-200;
    }

    &:focus {
      border: .188rem solid $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }
  }

  &.light {
    font-weight: 400;
    border-color: $color-blue-300;
    background-color: $color-blue-100;

    &:focus {
      border-color: $color-blue-900;
      background-color: $color-blue-200;
    }

    &:visited {
      color: $color-blue-500;
    }

    &:hover, &:active {
      color: darken($color-blue-500, 15%);
      background-color: $color-blue-200;
    }
  }

}

// END to TO DELETE AFTER DRUPAL CHANGES