.c-error-container {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(flex-end);
  @include flex-wrap(wrap);
  padding-bottom: 5rem;

  &.-align-center {
    @include align-items(center);
  }

  &__text {
    max-width: 40%;
  }

  &__img {
    max-width: 40%;

    &.-top {
      max-width: 100%;
    }
  }
}

@include tablet() {
  .c-error-container {
    @include justify-content(center);
    padding-bottom: 2rem;
    
    &__text {
      max-width: 100%;
    }

    &__img {
      max-width: 100%;
      margin-top: $spacing-l;
    }
  }
}