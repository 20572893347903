//sass-lint:disable-all

//burgundy
$color-burgundy-a500: #ba0047; 
$color-burgundy-100: #fbf6f8; 
$color-burgundy-300: #e0bdca; 
$color-burgundy-500: #900c3f; 
$color-burgundy: $color-burgundy-500;
$color-burgundy-700: #810134; 
$color-burgundy-900: #6a002a; 

//red
$color-red-a500: #f80000; 
$color-red-100: #fff8f8; 
$color-red-200: #fbd0d0; 
$color-red-300: #f99; 
$color-red-500: #ed1514; 
$color-red: $color-red-500;
$color-red-700: #c80001; 
$color-red-900: #9e0000; 

//orange
$color-orange-a500: #ff8600; 
$color-orange-100: #fef7f1; 
$color-orange-200: #FDEEDA;
$color-orange-300: #fdd9a8; 
$color-orange-500: #fb9612; 
$color-orange: $color-orange-500;
$color-orange-700: #e25910; 
$color-orange-900: #c43e00; 

//yellow
$color-yellow-a500: #ffe200; 
$color-yellow-100: #fdfde9; 
$color-yellow-200: #fafad2; 
$color-yellow-300: #fff79e; 
$color-yellow-500: #ffd82a; 
$color-yellow: $color-yellow-500; 
$color-yellow-700: #fcbd01; 
$color-yellow-900: #b88a02; 
$color-yellow-1100: #5e5008; 

//light-green
$color-lightgreen-200: #ebf5dd; 
$color-lightgreen-400: #d8f2a4; 
$color-lightgreen-500: #7eba19; 
$color-lightgreen: $color-lightgreen-500; 
$color-lightgreen-600: #6a9129; 

//green
$color-green-a500: #00D719; 
$color-green-100: #f7fcf7; 
$color-green-200: #EBF5DD;
$color-green-300: #c4edc9; 
$color-green-500: #5bcf6a; 
$color-green: $color-green-500;
$color-green-600: #00a14b; 
$color-green-700: #1d8229; 
$color-green-900: #104917; 

//turquoise
$color-turquoise-a500: #00c5b3; 
$color-turquoise-100: #f9fdfd; 
$color-turquoise-200: #eaf6f5; 
$color-turquoise-300: #c8e8e5; 
$color-turquoise-500: #49b4aa; 
$color-turquoise: $color-turquoise-500;
$color-turquoise-700: #0c887e; 
$color-turquoise-900: #004747; 

//blue
$color-blue-a500: #1a93ff; 
$color-blue-100: #eff7fe; 
$color-blue-200: #dbecf8; 
$color-blue-300: #c4def0; 
$color-blue-400: #82bfff; 
$color-blue-500: #2d89cc; 
$color-blue: $color-blue-500;
$color-blue-700: #0a5d98; 
$color-blue-900: #004475; 

//indigo
$color-indigo-a500: #002a7f; 
$color-indigo-100: #eef0f4; 
$color-indigo-300: #c4cbdb; 
$color-indigo-500: #2a4278; 
$color-indigo: $color-indigo-500;
$color-indigo-700: #102759; 
$color-indigo-900: #041847; 

//purple
$color-purple-a500: #48006a; 
$color-purple-100: #f4f3f6; 
$color-purple-300: #d0cbd6; 
$color-purple-400: #796694; 
$color-purple-500: #52426a; 
$color-purple: $color-purple-500;
$color-purple-700: #443757; 
$color-purple-900: #322a40; 

//grey
$color-grey-a500: #2F2F2F; 
$color-grey-100: #f8f7f8; 
$color-grey-200: #f1f2f3; 
$color-grey-300: #e3e5e7; 
$color-grey-400: #dcdcdc; 
$color-grey-500: #c9cbce; 
$color-grey: $color-grey-500;
$color-grey-600: #aeb0b6; 
$color-grey-700: #9097a0; 
$color-grey-800: #6a707b; 
$color-grey-900: #474b50; 

//black and white
$color-white: #fff; 
$color-black: #000; 

//specials
$transparent-black-00: transparentize($color-black, 1);
$transparent-black-05: transparentize($color-black, .95);
$transparent-black-10: transparentize($color-black, .9);
$transparent-black-15: transparentize($color-black, .85);
$transparent-black-20: transparentize($color-black, .8);
$transparent-black-25: transparentize($color-black, .75);
$transparent-black-30: transparentize($color-black, .7);
$transparent-black-40: transparentize($color-black, .6);
$transparent-black-50: transparentize($color-black, .5);
$transparent-black-60: transparentize($color-black, .4);
$transparent-black-70: transparentize($color-black, .3);
$transparent-black-75: transparentize($color-black, .25);
$transparent-black-80: transparentize($color-black, .2);
$transparent-black-90: transparentize($color-black, .1);
$transparent-black-95: transparentize($color-black, .05);

$transparent-white-00: transparentize($color-white, 1);
$transparent-white-15: transparentize($color-white, .85);
$transparent-white-25: transparentize($color-white, .75);
$transparent-white-50: transparentize($color-white, .5);
$transparent-white-75: transparentize($color-white, .25);

$transparent-blue-00: transparentize($color-blue, 1);
$transparent-blue-15: transparentize($color-blue, .85);
$transparent-blue-25: transparentize($color-blue, .75);
$transparent-blue-50: transparentize($color-blue, .5);
$transparent-blue-75: transparentize($color-blue, .25);



