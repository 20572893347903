.snackbar {
  position: fixed;
  bottom: 24px;
  left: 120px;
  display: none;
  padding: $spacing-s $spacing-s;
  // ANIMATION
  -webkit-animation: fadein .3s, fadeout .3s 2.7s;
  animation: fadein .3s, fadeout .3s 2.7s;
  border-radius: 5px;

  // MODIFIERS 

  &.-neutral {
    color: $color-white;
    background-color: $color-grey-900;
  }

  &.-warning {
    color: $color-orange-900;
    background-color: $color-orange-300;
  }

  &.-show {
    @include flexbox(inline-flex);
    @include align-items(center);
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 24px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 24px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 24px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 24px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }

  // styling elements 

  &__content {
    margin-right: $spacing-l;
    margin-bottom: 0;
  }

  &__action {
    font-weight: 500;
    margin: 0 $spacing-s;
    text-decoration: none;
    color: inherit;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: inherit;
    }
  }

  &__close {
    height: 100%;
    padding-left: $spacing-s;
    border-left: 1px solid;
    border-left-color: inherit;
  }
}