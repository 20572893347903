
// _article.scss
.c-article {
  position: relative;
  min-height: 254px;
  padding: $spacing-s;
  padding-top: 0;
  border-bottom: 1px solid $color-grey-500;

  &.-with-image {
    border: 1px solid $color-grey-500;
    border-radius: .5rem;

    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    grid-row: 1/3;
  }

  &.-no-border {
    padding: 0;
    border: 0;
  }

  &__image-box {
    height: 16.6rem;
    margin: -$spacing-s;
    margin-bottom: 3rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: .5rem .5rem 0 0;

    object-fit: cover;
  }

  &__category {
    margin-bottom: 0;
  }

  &__title {
    margin-top: 0;
  }

  &__description {
    display: -webkit-box;
    overflow: hidden;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
  }

  &__publishing-date {
    position: absolute;
    bottom: $spacing-m;
  }

  &__tag {
    margin-right: $spacing-s;
  }

  @include screenMedium () {
    position: static;
    min-height: 0;
    
    &__description {
      display: -webkit-box;
      overflow: hidden;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
    }

    &__publishing-date {
      position: static;
      margin-top: $spacing-s;   
    }
  }
}
