.c-slat {
  margin: $spacing-l 0;
  padding-bottom: $spacing-l;
  border-bottom: 1px solid $color-grey-500;

  &__header {
    @include flexbox();
    padding-bottom: $spacing-m;
  }

  &__title {
    margin-top: 0;
    margin-right: $spacing-s;
    margin-bottom: 0;

    &.-space-between {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      @include flex-wrap(wrap);
    }
  }

  &__actions{
    &.-space-between {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      @include flex-wrap(wrap);
    }

    &.-centered {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      text-align: center;
    }

    &.-left-padding {
      :not(:last-child) {
        margin-right: $spacing-s;
      }
    }

    &.-right-padding {
      text-align: right;
      :not(:first-child) {
        margin-left: $spacing-s;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    background-color: $color-grey-500;
  }

}

