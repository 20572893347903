.c-notification-bullet {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  display: inline-block;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  padding: $spacing-xs;
  text-align: center;
  letter-spacing: 1px;
  color: $color-white;
  border-radius: 2rem;
  background-color: $color-blue-700;
}