//colors
$color-link: $color-blue-500;
$color-hover: $color-blue-700;
$text-meta: $color-grey-700;
$color-muted: $color-grey-700;
$color-box-shadow: rgba(71, 75, 80, .4);
$color-success: $color-green-600;
$color-warning: $color-orange-a500;
$color-danger: $color-red-700;

// Grid V4
$wrapper-max-width: 1300px;

//spacing
$spacing-xxs: .25rem;
/* 4px */
$spacing-xs: .5rem;
/* 8px */
$spacing-s: 1rem;
/* 16px */
$spacing-m: 1.5rem;
/* 24px */
$spacing-l: 2rem;
/* 32px */
$spacing-ll: 3rem;
$spacing-xl: 4rem;
/* 64px */
$spacing-xxl: 7.5rem;
/* 120px */