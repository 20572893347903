/* check if the appearance:none attr is supported. 
If not, default browser styling will be used. */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  .radiobutton {
    /* used to make sure tht the second line of a label 
    starts at the same point as the first line (so not under the checkbox) */
    @include flexbox;
    font-weight: unset;
    line-height: 26px;
    position: relative;
    margin-right: $spacing-l;
    padding: 11px 0;
    cursor: pointer;
  }

  .input-type-radio {
    display: inline-block;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    margin-top: .3rem;
    margin-right: $spacing-xs;
    cursor: pointer;
    border: 1px solid $color-grey-a500;
    background-color: $color-white;
    /* hide the original browser radiobutton*/

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* general styling radiobutton */
  input[type='radio'] {
    @extend .input-type-radio;
    border-radius: 100%;

    /* styling for the circle in the radiobutton*/
    &:after {
      display: block;
      width: inherit;
      height: inherit;
      margin-top: -1px;
      margin-left: -1px;
      content: '';
      transition: .6s;
      transform: scale(.7);
      transform-origin: center center;
      opacity: 0;
      border-radius: inherit;
      background: $color-blue-500;

    }

    /* different states radiobutton */
    &:checked {
      border: 1px solid $color-blue-500;

      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: 2px solid $color-blue-400;
    }

    &:disabled {
      border-color: $color-grey-300;

      &:checked {
        border-color: $color-grey-500;

        &:after {
          opacity: 1;
          background-color: $color-grey-500;

        }
      }
    }
  }


}