.breadcrumb {
  @include flexbox();
  @include flex-wrap(wrap);
  margin-bottom: $spacing-s;

  ._breadcrumb-item {
    margin-right: $spacing-s;

    &:not(:first-child) {
      &:before {
        @extend .icon;
        @extend .icon-arrow-right;
        text-decoration: none;
      }
    }
  }
}