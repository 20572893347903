.c-dropdown {
  position: relative;
  display: inline-block;

  &__menu {
    @include flexbox();
    @include flex-direction(column);
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 160px;
    margin: 2px 0 0;
    padding: 0;
    list-style: none;
    text-align: left;
    border: 1px solid $color-grey-500;
    border-radius: 5px;
    background-color: $color-white;
    background-clip: padding-box;
    box-shadow: $color-box-shadow 0 2px 4px 0;

    // Dropdown for menu aligned to right side
    &.-right-align {
      right: 0;
      left: auto;
    }

    li:not([role=separator]) {
      margin: 0 !important;
      padding: 0 !important;

      &.-active {
        @include flex-order(-1);
        font-weight: 500;
      }

      a {
        display: block;
        padding: $spacing-xs $spacing-s;
        cursor: default; //the negate the effects of clickable topelements like a button
        white-space: nowrap;
        text-decoration: none;
        color: $color-grey-900;

        &:hover, &:focus {
          text-decoration: none;
          color: $color-white;
          background-color: $color-blue;
        }

        &[disabled] {
          color: $color-grey-600;
          background-color: $color-grey-100;

          &:hover {
            cursor: not-allowed;
            color: $color-grey-600;
          }
        }
      }

    }
  }


  &__meta {
    font-size: 1rem;
    white-space: normal;
    color: $color-muted;

    a:hover & {
      color: $color-white;
    }
  }

  &__divider {
    overflow: hidden;
    height: 1px;
    margin: $spacing-xxs $spacing-s;
    background-color: $color-grey-300;
  }
}