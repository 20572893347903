.c-language-menu {
  @include flexbox;
  @include flex-direction(row);

  &__language-item {
    display: block;
    margin: 0;
    padding: $spacing-xs;

    &:last-child {
      padding-right: 0;
    }
  }

  &__language-link {
    text-decoration: none;
    color: $color-grey-900;

    &.is-active {
      font-weight: 500;
    }
  }
}