.c-contact-block {

  &__block-title {
    color: $color-turquoise-700;

  }

  &__description {
    font-weight: 500;
    margin-bottom: 0;
    color: $color-grey-700;
  }

  &__name {
    font-weight: 500;
    margin-bottom: 0;
  }


  &__link-container {
    &:before {
      padding-right: $spacing-xs;
      text-decoration: none;
      color: $color-grey-700;
    }
  }

  &__link {

    &:first-child {
      display: inline;
      margin-top: $spacing-m;
      margin-bottom: 0;
    }

    &:not(:first-child) {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: $spacing-l;
    }



  }

}