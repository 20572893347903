//TODO REFACTOR BEM
.pagination {
  @include flexbox();
  @include justify-content(center);

  &.-block {
    padding: $spacing-s;
    border: 1px solid $color-grey-200;  //TODO, changed this to BEM
    background-color: $color-grey-100;
  }

  .pagination-list {
    @include flexbox();

    ._pages {
      @include flexbox();

      li:not(:first-child) {
        margin-left: $spacing-xl;
      }
    }

    ._prev {
      margin-right: $spacing-l;
    }

    ._next {
      margin-left: $spacing-l;
    }
  }
}

@include screen() {
  .pagination {

    &.-block {
      padding: $spacing-xs;
    }

    .pagination-list {
      
      ._pages {
        display: none;
      }
    }
  }
}