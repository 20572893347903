.basic-form {
  .field-select {
    .form-field-icon {
      .field-icon {
        padding: 0;
        pointer-events: none;

        &:before {
          font-size: 2rem;
          color: $color-grey-800;

          &:hover {
            color: $color-blue-500;
          }
        }

      }
    }
  }
}


select {
  @extend .text-inputfield-styling;
  cursor: pointer;
  color: $color-grey-800;
  background-color: $color-white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // background-image: url('../img/0002-arrow-down.svg');
  // background-repeat: no-repeat;
  // background-position: right 3% center;
  // background-size: 1rem;

  &.sideby {
    min-width: 200px;
    max-width: 100%;
    height: 48px;
  }
}

@include phone {
  select {
    width: 100%;
  }
}