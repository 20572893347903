.c-navigation-block {

  &__title {
    margin-top: $spacing-s;
    margin-bottom: $spacing-xxs;
  }

  &__content {
    margin-top: 0;
    padding-bottom: $spacing-s;
  }

  &__image-box {
    overflow: hidden;
    width: 100%;
    height: 12rem;
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

}
